import React from 'react';

import { RestaurantDetail, OrderType, Place } from '@bestelleck/utils';
import { DialogContent, styled, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';

import { AddressComponent } from '../../../components/Address/Address';
import { DialogTitle } from '../../../components/DialogTitle/DialogTitle';
import { Dispatch } from '../../../store/store';

import styles from './SelectOrderDialog.module.scss';

type SelectOrderDialogProps = {
    open: boolean;
    restaurant: RestaurantDetail;
    handleClose: (isDelivery: boolean) => void;
};

const StyleDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        overflowY: 'visible',
    },
}));

const SelectOrderDialog: React.FC<SelectOrderDialogProps> = ({ open, restaurant, handleClose }) => {
    const dispatch = useDispatch<Dispatch>();

    const smallScreen = useMediaQuery('(max-width:750px)');

    return (
        <div>
            <StyleDialog
                onClose={() => {
                    handleClose(false);
                }}
                aria-labelledby="customized-dialog-title"
                id="selectOrderDialog"
                open={open}
                fullWidth
                fullScreen={smallScreen}
                maxWidth="sm"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        handleClose(false);
                    }}
                >
                    Abholung oder Lieferung?
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.section}>
                        {restaurant.delivery && (
                            <div className={styles.address}>
                                <AddressComponent
                                    restaurant={restaurant}
                                    showHelp={true}
                                    onSelect={(place: Place) => {
                                        dispatch.orderType.setPlaceAndType({ place, orderType: OrderType.Delivery });
                                        handleClose(true);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </DialogContent>
            </StyleDialog>
        </div>
    );
};

export default SelectOrderDialog;
