import { ScheduleException } from "./schedule-exception.interface";

export const getVacationLabel = (exceptions: ScheduleException[]): string | null => {
    const date = new Date();
    if (!hasVacationException(exceptions, date)) {
        return null;
    }
    while (hasVacationException(exceptions, date)) {
        date.setDate(date.getDate() + 1);
    }

    // the iteration happens once to often, therefore remove one day
    date.setDate(date.getDate() - 1);
    const options = { month: "2-digit", day: "2-digit", year: "2-digit" } as const;
    //slice removes the trailing dot
    return "Urlaub bis " + date.toLocaleDateString("de", options);
};

export const hasVacationException = (exceptions: ScheduleException[], date: Date): boolean => {
    return exceptions.some((exception) => {
        const exceptionDate = new Date(exception.date);
        return (
            exceptionDate.toDateString() === date.toDateString() &&
            exception.reason === "" &&
            exception.frames.length === 0
        );
    });
};
