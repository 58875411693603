import React from 'react';

import { getDescriptionText } from '@bestelleck/shared';
import { getVacationLabel, OrderType } from '@bestelleck/utils';
import { Button, Skeleton, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '../../store/store';
import { baseUrl } from '../../util/constants';
import useWindowDimensions from '../../util/useWindowDimension';

import { DeliveryPlaces } from './DeliveryPlaces/DeliveryPlaces';
import { MenuFooter } from './Footer/Footer';
import { OpeningTimes } from './OpeningTimes/OpeningTimes';

import './Entry.css';

export const EntryScreen: React.FC = () => {
    const { restaurant } = useSelector((state: RootState) => state.restaurant);
    const { width } = useWindowDimensions();
    const isSmallScreen = useMediaQuery('(max-width:750px)');

    const imageWidth = isSmallScreen ? width * 1.5 : width;

    const hasPickup = restaurant?.orderTypes.includes(OrderType.Delivery);
    const hasDelivery = restaurant?.orderTypes.includes(OrderType.Delivery);
    const hasDeliveryAndPickup = hasPickup && hasDelivery;
    let deliveryText;

    let exceptions;

    if (hasDeliveryAndPickup && restaurant?.delivery) {
        deliveryText = 'Wir bieten Abholung und Lieferung an';
        exceptions = restaurant?.delivery?.exceptions;
    } else if (hasDelivery && restaurant?.delivery) {
        deliveryText = 'Wir bieten Lieferung an';
        exceptions = restaurant?.delivery?.exceptions;
    } else if (hasPickup && restaurant?.pickup) {
        deliveryText = 'Wir bieten Abholung an';
        exceptions = restaurant?.pickup?.exceptions;
    }

    const vacationLabel = getVacationLabel(exceptions || []);

    return (
        <div>
            <div className="imageContainer">
                {restaurant ? (
                    <img
                        alt=""
                        src={`${baseUrl}/image/${restaurant.imageLink}?width=${imageWidth}`}
                        className="image"
                    ></img>
                ) : (
                    <Skeleton variant="rectangular" className="image" />
                )}

                <div className="centered">
                    <h1 className="h1">{restaurant?.name}</h1>
                    <h3 className="h3">{restaurant?.description}</h3>
                </div>
                <div className="entryBox">
                    {vacationLabel && <div className="vacation"> Aktuell im {vacationLabel}</div>}
                    <div>{deliveryText}</div>
                    <Button color="primary" variant="contained" className="button" component={Link} to={'/menu'}>
                        Menü & Bestellen
                    </Button>
                </div>
            </div>

            <h2>Über {restaurant?.name}</h2>
            <hr className="divider"></hr>
            <div className="description">
                {restaurant?.info.map((info, index) => {
                    return (
                        <div className="infoEntry" key={index}>
                            {getDescriptionText(info)}
                        </div>
                    );
                })}
            </div>

            {restaurant && <OpeningTimes restaurant={restaurant} />}

            <DeliveryPlaces restaurant={restaurant} />
            {isSmallScreen && <MenuFooter />}
        </div>
    );
};
